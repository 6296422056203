import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Footer from "../Footer";
import axios from "axios";

const ViewMyCarlist = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const optionToast = {
     position: "top-right",
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "colored",
     transition: Bounce,
   };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);
  
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  const getAllMyCar = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/publish/car/list",
        {
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setData(response?.data?.data);
        console.log(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const publishedMyCar =()=>{
    setLoading(true);
    const formData = {
      owner_id: storedUserPreferences?.id,
    };

    axios
      .post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/save-for-publish",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          getAllMyCar();
          toast.success("All Cars Successfully Published");
        } else {
          toast.error(res?.data?.message, optionToast);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  const saveDraftMyCars =()=>{
    setLoading2(true);
    const formData = {
      owner_id: storedUserPreferences?.id,
    };

    axios
      .post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/save-draft",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setLoading2(false);
        if (res?.data?.status) {
          getAllMyCar();
          toast.success("All cars successfully saved as drafts");
        } else {
          toast.error(res?.data?.message, optionToast);
        }
      })
      .catch((err) => {
        setLoading2(false);
        console.log(err);
      });
  }
  useEffect(() => {
    getAllMyCar();
  }, []);

  const deleteMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/publish/car/delete",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        getAllMyCar();
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const editMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/publish/edit/car",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        navigate("/dealer/edit-saved/publish/cars", {
          state: { data: response?.data?.data },
        });
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "are you sure you want to delete the car data ?"
    );
    if (userConfirmed) {
      deleteMyCar(id);
    }
  };

  return (
    <>
      <Navbar />
      <div className="new_page">
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-md-between justify-content-center w-100 mb-3 px-md-2 px-0 flex-wrap">
            <h3 className="fs-5 fw-bold">Review Your Car Listings</h3>
            <Link to="/dealer/list-my-cars">
              <button
                className="btn_add p-2 py-1 mt-2"
                style={{ border: "none" }}
              >
                <i className="fa-solid fa-plus me-2"></i>
                Add More
              </button>
            </Link>
          </div>
          <p className="mt-3 mb-5 d-block">
            Here’s a preview of your listings below. Click on ‘Publish Your
            Cars’ to submit your listings. To amend your listing please visit
            your dashboard.
          </p>

          <div className="row">
            {data.length === 0 ? (
              <div></div>
            ) : (
              <div className="col-md-12">
                <div className="row">
                  {data.map((car) => (
                    <div
                      key={car.id}
                      className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
                    >
                      <div className="card  card_filter_card shadow-lg position-relative">
                        <div
                          className="image-container "
                          style={{ position: "relative" }}
                        >
                          <div className="row h-100">
                            <div className="col-9 h-100 pe-0">
                              <img
                                src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars/${car.image}`}
                                className="w-100"
                                loading="lazy"
                                alt="car"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderTopRightRadius: 0,
                                }}
                              />
                            </div>
                            <div className="col-3 h-100  pl-0">
                              <div className="row pl-0 h-100  grid_img">
                                {[...Array(3)].map((_, index) => {
                                  const image = car.gallery[index];
                                  if (image) {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: "33.4%",
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars-gallery/${image.image}`}
                                          alt=""
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          height: "33.4%",
                                          width: "100%",
                                          marginLeft: 0,
                                          paddingLeft: 0,
                                          objectFit: "fill",
                                        }}
                                        className="col-12 "
                                      >
                                        <img
                                          src="img/noimg.jpeg"
                                          alt="Placeholder"
                                          loading="lazy"
                                          className="h-100 w-100"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="delete-button btn btn-sm btn-danger"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            zIndex: "1",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(car.id)}
                        >
                          <i
                            className="bi bi-trash delete-icon"
                            style={{
                              color: "white",
                              fontSize: "17px",
                            }}
                          ></i>
                        </button>
                        <button
                          className=" btn btn-sm btn-primary"
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: "1",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => editMyCar(car.id)}
                        >
                          <i
                            className="fa fa-edit delete-icon"
                            style={{
                              color: "white",
                              fontSize: "17px",
                            }}
                          ></i>
                        </button>
                        <Link
                          to={`/dealer/saved/car/detail?car=${car?.slug}`}
                          className="card-body pb-1"
                        >
                          <div className="d-flex justify-content-between">
                            <h6
                              className="text-dark fw-semibold"
                              style={{ fontWeight: "14px" }}
                            >
                              ({car.year}) {car.make} {car.model}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h6 className="text-dark fw-bold">Price Range:</h6>
                            <h6 className="fw-bold price">
                              £{formatEuroAmount(car.min_price)} - £
                              {formatEuroAmount(car.max_price)}
                            </h6>
                          </div>
                          <hr className="hr" />
                          <div className="d-flex car_info justify-content-between">
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/automatic.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car.transmission_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/speedometer.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {formatNumber(car.current_mileage)}
                            </h6>
                            <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/fuel.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {car.fuel_type}
                            </h6>
                            <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                              <img
                                src="./img/engine.png"
                                style={{ height: "25px", width: "25px" }}
                                alt=""
                              />
                              {convertCcToLiters(car.engine_capacity)}L
                            </h6>
                          </div>

                          <hr className="hr" />
                          <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                            <div className="d-flex align-items-center">
                              {car?.owner?.city && (
                                <>
                                  <img
                                    src="./img/pin.png"
                                    style={{ height: "20px", width: "20px" }}
                                    alt=""
                                  />
                                  <h6 className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                                    {car?.owner?.city} ({car?.owner?.country})
                                  </h6>
                                </>
                              )}
                            </div>
                            <div>
                              <p className="text-primary fs-7 pb-0 mb-0">
                                More <i className="fa fa-arrow-right"></i>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row mt-5">
                  <div className="col-12 d-flex flex-column align-items-center">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={publishedMyCar}
                      className="btn px-5 btn-primary rounded-pill"
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Processing...
                        </>
                      ) : (
                        " Publish Your Cars"
                      )}
                    </button>
                    <button
                      type="button"
                      disabled={loading2}
                      onClick={saveDraftMyCars}
                      className="btn px-5 btn-primary rounded-pill mt-4"
                    >
                     {loading2 ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Processing...
                        </>
                      ) : (
                        "Save As Draft"
                      )}
                     
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
      <ToastContainer/>
    </>
  );
};

export default ViewMyCarlist;
