import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
// import SmallSearch from "../components/SmallSearch";
import axios from "axios";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
const MyListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };

  // Example usage:
  const storedUserPreferences = getDataFromlocalStorage("userData");
  // console.log(storedUserPreferences, "storedUserPreferencesstoredUserPreferences")

  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);

  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  const getAllMyCar = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/cars/mylist",
        {
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data);
      if (response?.data?.status) {
        setData(response?.data?.data);
        // Handle the data as needed, e.g., update state, render UI, etc.
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    getAllMyCar(); // Call the API when component mounts
  }, []);

  const deleteMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/delete",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data);
      if (response?.data?.status) {
        getAllMyCar();
        // Handle the data as needed, e.g., update state, render UI, etc.
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const editMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/edit/mycar",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data);
      if (response?.data?.status) {
        // console.log('hello',response?.data?.data);
        navigate("/edit/mycar", { state: { data: response?.data?.data } });
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "are you sure you want to delete the car data ?"
    );
    if (userConfirmed) {
      deleteMyCar(id);
    }
  };

  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div> */}
        {/* <SmallSearch /> */}

        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-between w-100 mb-3 px-2">
            <h3 className="fs-5 fw-bold">My Listings</h3>
            <Link to="/listdetails">
              <button className="btn_add p-2 mt-2" style={{ border: "none" }}>
                <i className="fa-solid fa-plus me-2"></i>
                Add Car
              </button>
            </Link>
          </div>
          <div className="row">
            {data.length === 0 ? (
              <div></div>
            ) : (
              data.map((car) => (
                <div
                  key={car.id}
                  className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
                >
                  <div className="card  card_filter_card shadow-lg position-relative">
                    <div
                      className="image-container "
                      style={{ position: "relative" }}
                    >
                      <div className="row h-100">
                        <div className="col-9 h-100 pe-0">
                          <img
                            src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars/${car.image}`}
                            className="w-100"
                            loading="lazy"
                            alt="car"
                            style={{
                              height: "100%",
                              width: "100%",
                              borderTopRightRadius: 0,
                            }}
                          />
                        </div>
                        <div className="col-3 h-100  pl-0">
                          <div className="row pl-0 h-100  grid_img">
                            {[...Array(3)].map((_, index) => {
                              const image = car.gallery[index];
                              if (image) {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      height: "33.3%",
                                      width: "100%",
                                      marginLeft: 0,
                                      paddingLeft: 0,
                                      objectFit: "fill",
                                    }}
                                    className="col-12 "
                                  >
                                    <img
                                      src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars-gallery/${image.image}`}
                                      alt=""
                                      loading="lazy"
                                      className="h-100 w-100"
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      height: "33.3%",
                                      width: "100%",
                                      marginLeft: 0,
                                      paddingLeft: 0,
                                      objectFit: "fill",
                                    }}
                                    className="col-12 "
                                  >
                                    <img
                                      src="img/noimg.jpeg"
                                      alt="Placeholder"
                                      loading="lazy"
                                      className="h-100 w-100"
                                    />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="delete-button btn btn-sm btn-danger"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        zIndex: "1",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(car.id)}
                    >
                      <i
                        className="bi bi-trash delete-icon"
                        style={{
                          color: "white",
                          fontSize: "17px",
                        }}
                      ></i>
                    </button>
                    <button
                      className=" btn btn-sm btn-primary"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: "1",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => editMyCar(car.id)}
                    >
                      <i
                        className="fa fa-edit delete-icon"
                        style={{
                          color: "white",
                          fontSize: "17px",
                        }}
                      ></i>
                    </button>
                    <Link
                      to={`/cardetails?car=${car?.slug}`}
                      className="card-body pb-1"
                    >
                      <div className="d-flex justify-content-between">
                        <h6
                          className="text-dark fw-semibold"
                          style={{ fontWeight: "14px" }}
                        >
                          ({car.year}) {car.make} {car.model}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 className="text-dark fw-bold">Price Range:</h6>
                        <h6 className="fw-bold price">
                          £{formatEuroAmount(car.min_price)} - £
                          {formatEuroAmount(car.max_price)}
                        </h6>
                      </div>
                      <hr className="hr" />
                      <div className="d-flex car_info justify-content-between">
                        <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                          <img
                            src="./img/automatic.png"
                            style={{ height: "25px", width: "25px" }}
                            alt=""
                          />
                          {car.transmission_type}
                        </h6>
                        <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                          <img
                            src="./img/speedometer.png"
                            style={{ height: "25px", width: "25px" }}
                            alt=""
                          />
                          {formatNumber(car.current_mileage)}
                        </h6>
                        <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                          <img
                            src="./img/fuel.png"
                            style={{ height: "25px", width: "25px" }}
                            alt=""
                          />
                          {car.fuel_type}
                        </h6>
                        <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                          <img
                            src="./img/engine.png"
                            style={{ height: "25px", width: "25px" }}
                            alt=""
                          />
                          {convertCcToLiters(car.engine_capacity)}L
                        </h6>
                      </div>

                      <hr className="hr" />

                      {car?.owner?.role === "dealer" ? (
                        <div className="mb-2 d-flex justify-content-between">
                          <div className="d-flex align-items-center ">
                            <div
                              style={{
                                height: "2.4rem",
                                width: "2.4rem",
                                border: "2px solid #ddd",
                                borderRadius: "50%",
                                overflow: "hidden",
                              }}
                              className="me-2 text-capitalize "
                            >
                              <img
                                src={(car?.owner?.profile!=="" && car?.owner?.profile!=null)?`https://stagingsite.carloca.co.uk/apis/public/uploads/users/${car?.owner?.profile}`:`https://stagingsite.carloca.co.uk/apis/public/uploads/user.jpg`}
                                alt=""
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                }}
                              />
                            </div>
                            <h6 className="my-0 text-primary fs-7 text-capitalize ">
                              {car?.owner?.first_name} {car?.owner?.last_name}
                            </h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="fa text-primary me-2 fa-phone"></i>
                            <a
                              className="fs-7 text-primary"
                              href={"tel+" + car?.owner?.phone}
                            >
                              {car?.owner?.phone}
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="mb-2 pt-3 d-flex align-items-center justify-content-between">
                          <h6
                            className="text-primary   fw-medium d-flex align-items-center text-cap
                            alize"
                          >
                            Private Seller
                          </h6>
                          {car?.owner?.phone && (
                            <div className="d-flex align-items-center">
                              <i className="fa text-primary me-2 fa-phone"></i>
                              <a
                                className="fs-7 text-primary"
                                href={"tel+" + car?.owner?.phone}
                              >
                                {car?.owner?.phone}
                              </a>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                        <div className="d-flex align-items-center">
                          {car?.owner?.city && (
                            <>
                              <img
                                src="./img/pin.png"
                                style={{ height: "20px", width: "20px" }}
                                alt=""
                              />
                              <h6 className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                                {car?.owner?.city} ({car?.owner?.country})
                              </h6>
                            </>
                          )}
                        </div>
                        <div>
                          <p className="text-primary fs-7 pb-0 mb-0">
                            More <i className="fa fa-arrow-right"></i>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* data-bs-toggle="modal" data-bs-target="#exampleModal" */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rounded-0">
              <div className="modal-body ">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="img/slide1.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide2.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide3.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0 py-1">
                <button
                  type="button"
                  className="btn btn-secondary rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyListing;
