import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Navbar from "../components/Navbar";
import Footer from "../Footer";

import { useLocation } from "react-router-dom";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarDetailSlider from "../components/CarDetailSlider";
const ReadMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="carDescription">
      <div dangerouslySetInnerHTML={{ __html: renderText() }} />
      {text.length > maxLength && (
        <span
          onClick={toggleReadMore}
          style={{ color: "#00889c", cursor: "pointer" }}
        >
          {isExpanded ? " Read Less" : " Read More"}
        </span>
      )}
    </div>
  );
};
const ViewSavedCarDetail = () => {
  const [data, setData] = useState([]);
  const navigate = new useNavigate();
  const location = useLocation();
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  async function fetchedDetail(slug) {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/publish/car/detail",
        {
          user_id: storedUserPreferences?.id,
          slug: slug,
        }
      );
      if (response?.data?.status) {
        setData(response?.data?.data);
      } else {
        console.error(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get("car");
    if (!slug) {
      return;
    }
    fetchedDetail(slug);
  }, [location.search]);
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  const back = () => {
    navigate(-1);
  };
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };
  return (
    <>
      <Navbar />
      <div className="new_page" style={{ minHeight: "100vh" }}>
        
        {data?.min_price ? (
          <div className="detail_card container w-100 mt-5 py-5">
            <h2 className="fw-bold text-center mb-3">Car Details</h2>
            <div className="col-lg-7 col-md-9 col-sm-11 mx-auto">
              <div className="text-end">
                <button className="btn btn-primary mb-3" onClick={back}>
                  <i className="fa-solid fa-arrow-left me-2"></i>
                  Back
                </button>
              </div>

              <div className="card border position-relative">
                <div className="card-body">
                  <div className="position-relative w-100">
                    <CarDetailSlider data={data?.gallery} image={data?.image} />
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    {data?.owner?.city && (
                      <div>
                        <h6 className="fs-7 text-muted">
                          <i className="fa fa-location-dot"></i>
                          <span className="ms-2">
                            {data?.owner?.city} ({data?.owner?.country})
                          </span>
                        </h6>
                      </div>
                    )}
                  </div>

                  <div className="border rounded-3 py-3 px-2  mb-3">
                    <ul className="p-0 d-flex justify-content-between flex-wrap gap-2 carinfo  m-0 ">
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Make
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.make}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Model
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.model}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Year
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.year}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Color
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.color}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Body Type
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.body_type}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-dark fw-bold">Price Range:</h6>
                    <h6 className="fw-bold price">
                      £{formatEuroAmount(data?.min_price)} - £
                      {formatEuroAmount(data?.max_price)}
                    </h6>
                  </div>
                  <hr className="hr" />
                  <div className="d-flex car_info justify-content-between flex-wrap">
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/automatic.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.transmission_type}
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/speedometer.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {formatNumber(data?.current_mileage)}
                    </h6>
                    <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/fuel.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.fuel_type}
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/engine.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {convertCcToLiters(data?.engine_capacity)}L
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/seating.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.seating_capacity}
                    </h6>
                  </div>
                  <hr className="hr" />

                  {data?.owner?.role === "dealer" ? (
                    <div className="mb-2 d-flex justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div
                          style={{
                            height: "2.4rem",
                            width: "2.4rem",
                            border: "2px solid #ddd",
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                          className="me-2 text-capitalize "
                        >
                          <img
                            src={`https://stagingsite.carloca.co.uk/apis/public/uploads/users/${data?.owner?.profile}`}
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        </div>
                        <h6 className="my-0 text-primary fs-7 text-capitalize ">
                          {data?.owner?.first_name} {data?.owner?.last_name}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fa text-primary me-2 fa-phone"></i>
                        <a
                          className="fs-7 text-primary"
                          href={"tel+" + data?.owner?.phone}
                        >
                          {data?.owner?.phone}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2 d-flex ">
                      <h6 className="text-primary   fw-medium d-flex align-items-center text-capalize">
                        Private Seller
                      </h6>
                    </div>
                  )}

                  <h5>Description</h5>
                  <div className="moreTextshow">
                    <ReadMore text={data?.specifications} maxLength={100} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="text-center w-100 mt-5 d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewSavedCarDetail;
