import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../Footer";
import axios from "axios";
import DataTable from "react-data-table-component";
const AllMyCars = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };

  const getAllMyCar = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/cars/all-mylist",
        {
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    getAllMyCar();
  }, []);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const handleSelectedRowsChange = ({ selectedRows }) => {
    const ids = selectedRows.map((row) => row.id);
    setSelectedRowIds(ids);
  };
  const publishMyCars = async () => {
    try {
      setLoading2(true);
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/publish-and-unpublish",
        {
          car_ids: selectedRowIds,
          status: 1,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setLoading2(false);
        getAllMyCar();
      } else {
        setLoading2(false);
        alert(response?.data?.message);
      }
    } catch (error) {
      setLoading2(false);
      console.log("An error occurred while fetching data.", error);
    }
  };
  const unpublishMyCars = async () => {
    try {
      setLoading1(true);
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/publish-and-unpublish",
        {
          car_ids: selectedRowIds,
          status: 0,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setLoading1(false);
        getAllMyCar();
      } else {
        setLoading1(false);
        alert(response?.data?.message);
      }
    } catch (error) {
      setLoading1(false);
      console.log("An error occurred while fetching data.", error);
    }
  };
  const columnsData = [
    {
      name: "Make",
      selector: (row) => row.make,
      sortable: true,
    },
    {
      name: "Model",
      selector: (row) => row.model,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
      width: "80px",
    },
    {
      name: "Colour",
      selector: (row) => row.color,
      width: "80px",
    },
    {
      name: "Body Type",
      selector: (row) => row.body_type,
    },
    {
      name: "Price",
      selector: (row) => formatNumber(row.offer_price),
      width: "80px",
    },
    {
      name: "Date Added",
      selector: (row) => row.created_date,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 1 ? "bg-success rounded-0" : "bg-danger rounded-0"
          }`}
        >
          {row.status === 1 ? "Published" : "Unpublished"}
        </span>
      ),
      width: "90px",
    },
    {
      name: "Preview",
      cell: (row) => (
        <Link
          to={
            row.status === 1
              ? `/cardetails?car=${row?.slug}`
              : `/dealer/saved/car/detail?car=${row?.slug}`
          }
          className="btn btn-sm btn-outline-light p-0 text-dark"
          style={{ fontSize: 12 }}
        >
          Click Here
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "80px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <button
          onClick={() => editMyCar(row?.id)}
          className="btn btn-sm"
          type="button"
        >
          <i className="fa fa-edit"></i>
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          onClick={() => handleDelete(row.id)}
          className="btn text-danger btn-sm"
        >
          <i className="fa fa-trash"></i>
        </button>
      ),
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [filterText, setFilterText] = useState("");

  const filteredItems = data.filter(
    (item) =>
      (item.make &&
        item.make.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.model &&
        item.model.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.body_type &&
        item.body_type.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.color &&
        item.color.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.year &&
        String(item.year).toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponent = (
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={(e) => setFilterText(e.target.value)}
      style={{
        marginBottom: "10px",
        padding: "5px 10px",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
  );
  const deleteMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/delete",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        getAllMyCar();
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const editMyCar = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/edit/mycar",
        {
          car_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        if (response?.data?.data?.status === 1) {
          navigate("/edit/mycar", { state: { data: response?.data?.data } });
        } else {
          navigate("/dealer/edit-saved/publish/cars", {
            state: { data: response?.data?.data },
          });
        }
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "are you sure you want to delete the car data ?"
    );
    if (userConfirmed) {
      deleteMyCar(id);
    }
  };

  return (
    <>
      <Navbar />
      <div className="new_page">
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap w-100 mb-3 px-2">
            <h3 className="fs-5 fw-bold mb-2">My Listing</h3>
            <div className="d-flex flex-wrap">
              <button
                type="button"
                onClick={unpublishMyCars}
                disabled={loading1}
                className="btn btn-primary border-0 mb-2 me-2"
              >
                {loading1 ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Processing...
                  </>
                ) : (
                  "Unpublish"
                )}
              </button>
              <button
                type="button"
                onClick={publishMyCars}
                disabled={loading2}
                className="btn btn-primary border-0 mb-2 me-2"
              >
                {loading2 ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Processing...
                  </>
                ) : (
                  "Publish"
                )}
              </button>
              <Link
                to="/dealer/dashboard"
                className="btn btn-primary border-0 me-2 mb-2"
              >
                Back To Dashboard
              </Link>
              <Link
                to="/dealer/list-my-cars"
                className="btn btn-primary border-0 mb-2"
              >
                + Add Car
              </Link>
            </div>
          </div>
          <div className="card p-2 mt-5">
            {data.length === 0 ? (
              <div></div>
            ) : (
              <DataTable
                columns={columnsData}
                data={filteredItems}
                selectableRows
                onSelectedRowsChange={handleSelectedRowsChange}
                pagination
                responsive
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponent}
                highlightOnHover
                customStyles={{
                  header: {
                    style: {
                      fontSize: "18px",
                      fontWeight: "bold",
                    },
                  },
                  rows: {
                    style: {
                      fontSize: "14px",
                    },
                  },
                }}
              />
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AllMyCars;
