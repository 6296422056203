import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const AcceptOffer = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(""); // State to store API message
  const location = useLocation();

  useEffect(() => {
    const source = axios.CancelToken.source(); 
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get("on");

    if (!slug) {
      setError("Request id is missing in the URL.");
      setLoading(false);
      return;
    }

    // API call to update car status
    axios
      .post(`https://stagingsite.carloca.co.uk/apis/public/api/status/bidding-buyer`, {
        status: "accept-by-buyer",
        unid: slug,
      },{ cancelToken: source.token })
      .then((response) => {
        if(response?.data?.status){
            setMessage(response?.data?.message || "Request processed successfully."); 
            setLoading(false);
        }else{
            setError(response?.data?.message || "Request failed.");
            setLoading(false);

        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
            console.log("Request canceled:", err.message);
          } else {
            setError(err.response?.data?.message || "Failed to update car status.");
            setLoading(false);
          }
      });
      return () => {
        source.cancel("Operation canceled by the user."); // Cancel the request on component unmount
      };
  }, [location.search]);

  if (loading) {
    return <div style={{textAlign:"center",height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>Loading...</div>;
  }

  if (error) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        
        <div style={{ textAlign: "center", color: "red" }}>Error: {error}</div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.heading}>Thank You!</h1>
        <p style={styles.message}>
          We appreciate your continued interest in this car.
        </p>
        <p style={styles.message}>{message}</p>
        <p style={styles.footer}>Your dream car is closer than you think!</p>
        <button
          style={styles.button}
          onClick={() => window.open("https://stagingsite.carloca.co.uk/#/unlockedcars", "_blank")}
        >
          View Unlocked Car 
        </button>
      </div>
    </div>
  );
};

export default AcceptOffer;

// Styles (optional inline styling for simplicity)
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
  },
  card: {
    textAlign: "center",
    padding: "30px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "500px",
    width: "90%",
  },
  heading: {
    fontSize: "2rem",
    color: "#00889C",
    marginBottom: "15px",
    fontWeight: "600",
  },
  message: {
    fontSize: "1.2rem",
    color: "#333333",
  },
  footer: {
    marginTop: "20px",
    fontSize: "1rem",
    color: "#888888",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#ffffff",
    backgroundColor: "#00889C",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
  },
};
