import React, { useRef } from "react";
import LightGallery from "lightgallery/react";

// Import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";

// Import plugins
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const CarDetailSlider = ({ data }) => {
  const lightGalleryRef = useRef(null);

  const imageUrls =
    data?.map(
      (slide) =>
        `https://stagingsite.carloca.co.uk/apis/public/uploads/cars-gallery/carbig/${slide.image}`
    ) || [`https://stagingsite.carloca.co.uk/apis/public/uploads/dummy.png`];

  const openGallery = () => {
    if (lightGalleryRef.current) {
      lightGalleryRef.current.openGallery(0); // Open the gallery at the first image
    }
  };

  return (
    <div>
      {/* Display the first image at full width and height */}
      <div
        className="main-image-container"
        style={{
          position: "relative",
          maxWidth: "100%",
          overflow: "hidden",
        }}
        onClick={openGallery} // Open LightGallery when clicking the image
      >
        <img
          src={imageUrls[0]}
          alt="Main Gallery"
          className="rounded-2"
          style={{
            width: "100%",
            objectFit: "cover", // Ensures the image fills the container
            cursor: "pointer",
          }}
        />
        {imageUrls.length > 1 && (
          <span
            style={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
              background: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "20px",
              fontSize: "14px",
            }}
          >
            {`+${imageUrls.length - 1}`}
          </span>
        )}
      </div>

      {/* LightGallery Component */}
      <LightGallery
        onInit={(ref) => (lightGalleryRef.current = ref.instance)} // Capture LightGallery instance
        plugins={[lgThumbnail, lgZoom]}
        speed={500}
        mode="lg-fade"
        dynamic
        dynamicEl={imageUrls.map((url) => ({
          src: url,
          thumb: url,
        }))}
        style={{ display: "none" }} // Hide the gallery component itself
      />
    </div>
  );
};

export default CarDetailSlider;
