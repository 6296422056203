import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Bounce, ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import '../dashPages/plan.css'
import Swal from "sweetalert2";
import { useLocation,useNavigate } from "react-router-dom";
import CheckoutUpgradeForm from "../components/CheckoutUpgradeForm";
const stripePromise = loadStripe("pk_test_4QBduHSstG91diuSlCElknos");

const CheckoutUpgrade = () => {
  const navigate = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };

  const [clientSecret, setClientSecret] = useState("");

  const { state } = useLocation();
  const { plan } = state;

  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  useEffect(() => {
    if (storedUserPreferences === null) {
         Swal.fire({
           title: "Unauthorized",
           text: "You need to be logged in to access this page",
           icon: "warning",
           showCancelButton: true,
           confirmButtonColor: "#00889C",
           cancelButtonColor: "#d33",
           confirmButtonText: "Log In Now",
         }).then((result) => {
           if (result.isConfirmed) {
             navigate("/login");
           } else {
             navigate("/");
           }
         });
         return;
       } else if (storedUserPreferences?.role !== "dealer") {
         Swal.fire({
           title: "Unauthorized Access",
           text: "Only dealer can access this!",
           icon: "warning",
           showCancelButton: false,
           confirmButtonColor: "#00889C",
           cancelButtonColor: "#d33",
           confirmButtonText: "Back To Home",
         }).then((result) => {
           if (result.isConfirmed) {
             navigate("/");
           } else {
             navigate("/");
           }
         });
         return;
       }
    getCredits();
  }, []);

  // Create PaymentIntent as soon as the page loads
  const getCredits = async () => {
    
    await fetch(
      "https://stagingsite.carloca.co.uk/apis/public/api/create-payment-intent2",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: plan?.price }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.status) {
          setClientSecret(data.data.clientSecret);
        } else {
          toast.error(data.message, optionToast);
        }
      });
   
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row my-5">
          <div className="text-center col-12 mt-5">
            <h5 className="text-center bg-primary py-2">Checkout</h5>
            <p className="text-center  fw-lighter">
              Thank you for signing up to the plan below. As soon as you have
              purchased your plan you will be re-directed to your inventory page
              so you can start adding your cars.
            </p>
          </div>
          <div className="col-12">
            <div className="card rounded-2" style={{ border: "2px solid #089fb6" }}>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-md-5 mt-5">
                    <div
                      style={{ maxHeight: "27rem" }}
                      className={`card h-100 plancard`}
                      key={plan?.code}
                    >
                      <div className="card-body position-relative">
                        <h4 className="fs-4 fw-bold plan_heading">
                          {plan?.name}
                        </h4>
                        <p className="fw-lighter  subtext">{plan?.subtext}</p>
                        <div className="d-flex align-items-center">
                          <span className="plan_price fs-3 me-2 fw-bolder">
                            &pound;{plan?.price}
                          </span>
                          <span className="month">per {plan?.period}</span>
                        </div>
                        <hr />
                        <ul className="list-unstyled">
                          {plan.features.map((feature, i) => (
                            <li className="list-group-item mt-2">
                              <i className="fa fa-check me-2"></i>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5 ">
                    <div className="row justify-content-center" id="formtag">
                      {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutUpgradeForm plan={plan}/>
                        </Elements>
                      )}
                    </div>
                  </div>
                  <small className="text-muted text-center mt-3">
                    Powered by Stripe
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CheckoutUpgrade;
