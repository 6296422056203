import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Bounce, toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function CheckoutUpgradeForm({ plan }) {
 const navigate = useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const saveDataTolocalStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  };
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");

          break;
        case "processing":
          setMessage("Your payment is processing.");
          setCardData([]);
          setPaymentInfo([]);
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setCardData([]);
          setPaymentInfo([]);
          break;
        default:
          setMessage("Something went wrong.");
          setCardData([]);
          setPaymentInfo([]);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          paymentInfo: paymentInfo,
          cardData: cardData,
        },
        redirect: "if_required",
      });

      // console.log("Error:", error);
      //   console.log("PaymentIntent:", paymentIntent);

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred. Please refresh the page");
        }
      } else {
        setMessage("Payment succeeded!");
        try {
          const response = await axios.post(
            "https://stagingsite.carloca.co.uk/apis/public/api/upgrade-dealer",
            {
              user_id: storedUserPreferences?.id,
              amount: paymentIntent?.amount / 100,
              car_limit: plan?.carLimit,
              type: plan?.period+'ly',
              plan_code: plan?.code,
              pay_id: paymentIntent?.id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
         
          if (response?.data?.status) {
            setIsLoading(true);
            toast.success("Payment succeeded!", optionToast);
            localStorage.removeItem('userData');
            saveDataTolocalStorage("userData", response?.data?.user);
            setTimeout(() => {
                navigate("/dealer/success", { state: { "payment_id":paymentIntent?.id,"amount":paymentIntent?.amount/100 } });
            }, 2000);
          } else {
            toast.error(
              "Payment succeeded but there was an error storing the data.",
              optionToast
            );
          }
        } catch (error) {
          toast.error(
            "Payment succeeded but there was an error storing the data.",
            optionToast
          );
        }
      }
    } catch (error) {
      toast.error("Error confirming payment: " + error, optionToast);

      setMessage("An unexpected error occurred. Please refresh the page");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
