import React, { useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const RejectedRequest = () => {
  const [rejectList, setRejectList] = useState([]);
  const [selectId, setSelectId] = useState();
  const [userId, setUserId] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const buttonRef = useRef(null);
  // const [contactInfo, setContactInfo] = useState()
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  const acceptRejectBid = async (id, status) => {
    // console.log(id, status, userId, "stattst")
    setIsProcess(true);
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/status/bidding",
        {
          user_id: userId,
          bid_id: id,
          status: status,
        }
      );
      console.log(response);
      setIsProcess(false);
      if (buttonRef.current) {
        buttonRef.current.click();
      }
      fetchData();
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function convertCcToLiters(cc) {
    if(cc>0){

      return (cc / 1000).toFixed(1);
    }else{
      return 0;
    }
  }
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/owner/bids",
        {
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "rejeetete");
      const filteredData = response?.data.biddings.filter(
        (item) => item.status === "reject"
      );

      setRejectList(filteredData);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
                <SmallSearch /> */}
        <div
          className="pending_unlocks all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "70vh" }}
        >
          <h3 className="py-3 fw-bold fs-5">Rejected Offers</h3>
          <div className="row">
            {rejectList?.map((car) => (
              <div
                key={car?.id}
                className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
              >
                <div className="card  card_filter_card shadow-lg position-relative">
                  <div
                    className="image-container "
                    style={{ position: "relative" }}
                  >
                    <div className="row h-100">
                      <div className="col-9 h-100 pe-0">
                        <img
                          src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars/${car?.car?.image}`}
                          className="w-100"
                          loading="lazy"
                          alt="car"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderTopRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="col-3 h-100  pl-0">
                        <div className="row pl-0 h-100 grid_img">
                          {[...Array(3)].map((_, index) => {
                            const image = car?.car?.gallery[index];
                            if (image) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars-gallery/${image.image}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src="img/noimg.jpeg"
                                    alt="Placeholder"
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`/cardetails?car=${car?.car?.slug}`}
                        className="text-dark fw-semibold"
                        style={{ fontWeight: "14px" }}
                      >
                        ({car?.car?.year}) {car?.car?.make} {car?.car?.model}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark fw-bold">Price Range:</h6>
                      <h6 className="fw-bold price">
                        £{formatEuroAmount(car?.car?.min_price)} - £
                        {formatEuroAmount(car?.car?.max_price)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center column mt-2 flex-wrap">
                     
                      <button
                        style={{ fontSize: "12px" }}
                        className="btn btn-primary mb-2 btn-sm new_btn w-100"
                        data-bs-toggle="modal"
                        onClick={() => {
                          setSelectId(car.id);
                          setUserId(car?.user_id);
                        }}
                        data-bs-target="#exampleModal1"
                      >
                        Accept Communication
                      </button>
                    </div>
                    <hr className="hr" />
                    <div className="d-flex car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car?.car?.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car?.car?.engine_capacity)}L
                      </h6>
                    </div>

                    <hr className="hr" />

                    <div className="d-flex justify-content-between">
                      <h6 className="sml text-dark">Request From:</h6>
                      <h6 className="price ">
                        {car.bidder.first_name} {car.bidder.last_name}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Offer from the user:</h6>
                      <h6 className="fw-bold price">£ {formatEuroAmount(car.amount)}</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Request Type:</h6>
                      <h6 className="fw-bold text-capitalize">{car.bid_request}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
         {/* Accept offer popup */}
         <div className="modal fade " id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center mt-3 small_modal">
                <h5 className="">
                  Are you sure you want to accept this request?
                </h5>

                {/* <h4 className='blue'>£65000</h4> */}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    
                    onClick={() => acceptRejectBid(selectId, "again-accept")}
                    className="btn btn-primary btn-sm mx-2"
                    // data-bs-dismiss="modal"
                    disabled={isProcess}
                  >
                    {isProcess?"Processing...":"Yes"}
                  </button>
                  <button
                    className="btn btn-danger btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  ref={buttonRef}
                  className="btn btn-secondary btn-sm d-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Deny offer popup */}
        <div className="modal fade"  id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center mt-3 small_modal">
                <h5 className="">
                  Are you sure you want to deny this request?
                </h5>
                {/* <h4 className='blue'>£65000</h4> */}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    onClick={() => acceptRejectBid(selectId, "reject")}
                    className="btn btn-primary btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-danger btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
       
        <Footer />
      </div>
    </>
  );
};

export default RejectedRequest;
