import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../Footer";
const ReadMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="carDescription">
      <div dangerouslySetInnerHTML={{ __html: renderText() }} />
      {text.length > maxLength && (
        <span
          onClick={toggleReadMore}
          style={{ color: "#00889c", cursor: "pointer" }}
        >
          {isExpanded ? " Read Less" : " Read More"}
        </span>
      )}
    </div>
  );
};
function ThankYou() {
  const location = useLocation();
  const carArray = location.state.data;
  const [car, setCar] = useState(carArray.data);
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + 'M'; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + 'L'; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K'; // Thousands
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if(cc>0){

      return (cc / 1000).toFixed(1);
    }else{
      return 0;
    }
  }
  return (
    <>
      <Navbar />
      <div className="row border-0 justify-content-center mt-5">
        <div className="col-md-10">
          <h4 className="text-center fs-3 my-4 thank_heading mt-5">
            Thank You!
          </h4>
          <p className="thank_text">
            For listing your car with <Link to={"/"}>Carloca</Link>, Here's your
            a preview of your listing below. To amend your listing please visit
            your dashboard.
          </p>
          <div className="row border-0 mt-4 justify-content-center">
            <div className="col-md-8 col-sm-10  mb-5">
              <div className="card border-0 shadow-lg">
                <div className="card-body">
                  <div className="car_img">
                    <img
                      src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars/${car?.image}`}
                      className="rounded-3 h-100 w-100"
                      alt=""
                    />
                  </div>
                  <div className="card_detail_list mt-4">
                    <ul>
                      <li>
                        <span className="list-heading">Make:</span>
                        <span className="list-text">{car?.make}</span>
                      </li>
                      <li>
                        <span className="list-heading">Model:</span>
                        <span className="list-text">{car?.model}</span>
                      </li>
                      <li>
                        <span className="list-heading">Model Variant:</span>
                        <span className="list-text">{car?.model_varient}</span>
                      </li>
                      <li>
                        <span className="list-heading">Year:</span>
                        <span className="list-text">{car?.year}</span>
                      </li>
                      <li>
                        <span className="list-heading">Colour:</span>
                        <span className="list-text">{car?.color}</span>
                      </li>
                      <li>
                        <span className="list-heading">Min Price:</span>
                        <span className="list-text">£{formatEuroAmount(car?.min_price)}</span>
                      </li>
                      <li>
                        <span className="list-heading">Max Price:</span>
                        <span className="list-text">£{formatEuroAmount(car?.max_price) || "N/A"}</span>
                      </li>
                     
                    </ul>
                  </div>
                  <div className="car_spacification ps-2">
                    <p className="fw-bold text-primary " style={{fontSize:"15px"}}>Spacifications:</p>
                    <div className="moreTextshow">
                      <ReadMore text={car?.specifications} maxLength={100} />
                    </div>
                    <hr className="hr" />
                    <div className="d-flex car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car.engine_capacity)}L
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                    <img
                      src="./img/seating.png"
                      style={{ height: "25px", width: "25px" }}
                      alt=""
                    />
                    {car?.seating_capacity}
                  </h6>
                    </div>

                    <hr className="hr" />
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <Link to={"/"} className="primay-btn">
                  Return To Home
                </Link>
              </div>
            </div>
            <div className="col-md-12 my-5">
              <div className="card rounded-0 shadow-lg mb-3 car_info_strip car_info_strip_color">
                <div className="card-body pb-0 py-3">
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Lorem
                      ipsum dolor sit amet consectetur ad.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card rounded-0 shadow-lg mb-3 car_info_strip ">
                <div className="card-body pb-0 py-3">
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Lorem
                      ipsum dolor sit amet consectetur ad.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card rounded-0 shadow-lg mb-3 car_info_strip ">
                <div className="card-body pb-0 py-3">
                  <ul>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i> Lorem
                      ipsum dolor sit amet consectetur ad.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ThankYou;
