import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

const SuccessfullyUpgrade = () => {
  const navigator=useNavigate();
  
    const { state } = useLocation();
   const { payment_id,amount } = state;
  setTimeout(() => {
    navigator('/dealer/dashboard');
  }, 5000);
  return (
    <>
      <Navbar />
      <div className="row justify-content-center mt-5 pt-5">
        <div className="col-lg-5 mt-5">
          <div className="card">
            <div className="card-body text-center">
              <i class="fa fa-check-circle" style={{fontSize:55,color:"#28a745"}} aria-hidden="true"></i>
              <h4 className="text-success fw-bolder fs-4 mt-3">Payment Successful!</h4>
              
              <p>Payment Id: {payment_id}</p>
              
              <p>Amount :  £ {amount}</p>
              
              <small className="text-center text-muted">You will shortly be redirected back to the dashboard.</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessfullyUpgrade;
