import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
// import SmallSearch from "../components/SmallSearch";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const AppRequests = () => {
  const [approvedList, setApprovedList] = useState([]);
  const [contactInfo, setContactInfo] = useState();
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const storedUserPreferences = getDataFromlocalStorage("userData");

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/accepted/offer/bids",
        {
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "ddssd");
      setApprovedList(response?.data.biddings);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
        <SmallSearch/> */}
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "70vh" }}
        >
          <h3 className="py-3 fw-bold fs-5">Approved Offers</h3>
          <div className="row">
            {approvedList?.map((car) => (
              <div
                key={car?.id}
                className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
              >
                <div className="card  card_filter_card shadow-lg position-relative">
                  <div
                    className="image-container "
                    style={{ position: "relative" }}
                  >
                    <div className="row h-100">
                      <div className="col-9 h-100 pe-0">
                        <img
                          src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars/${car?.car?.image}`}
                          className="w-100"
                          loading="lazy"
                          alt="car"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderTopRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="col-3 h-100  pl-0">
                        <div className="row pl-0 h-100 grid_img">
                          {[...Array(3)].map((_, index) => {
                            const image = car?.car?.gallery[index];
                            if (image) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src={`https://stagingsite.carloca.co.uk/apis/public/uploads/cars-gallery/${image.image}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src="img/noimg.jpeg"
                                    alt="Placeholder"
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`/cardetails?car=${car?.car?.slug}`}
                        className="text-dark fw-semibold"
                        style={{ fontWeight: "14px" }}
                      >
                        ({car?.car?.year}) {car?.car?.make} {car?.car?.model}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark fw-bold">Price Range:</h6>
                      <h6 className="fw-bold price">
                        £{formatEuroAmount(car?.car?.min_price)} - £
                        {formatEuroAmount(car?.car?.max_price)}
                      </h6>
                    </div>
                    <hr className="hr" />
                    <div className="d-flex car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car?.car?.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car?.car?.engine_capacity)}L
                      </h6>
                    </div>

                    <hr className="hr" />

                    {car?.owner?.role === "dealer" ? (
                      <div className="mb-2 d-flex justify-content-between">
                        <div className="d-flex align-items-center ">
                          <div
                            style={{
                              height: "2.4rem",
                              width: "2.4rem",
                              border: "2px solid #ddd",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                            className="me-2 text-capitalize "
                          >
                            <img
                              src={`https://stagingsite.carloca.co.uk/apis/public/uploads/users/${car?.owner?.profile}`}
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                            />
                          </div>
                          <h6 className="my-0 text-primary fs-7 text-capitalize ">
                            {car?.owner?.first_name} {car?.owner?.last_name}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="fa text-primary me-2 fa-phone"></i>
                          <a
                            className="fs-7 text-primary"
                            href={"tel+" + car?.owner?.phone}
                          >
                            {car?.owner?.phone}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-2 pt-3 d-flex align-items-center justify-content-between">
                        <h6
                          className="text-primary   fw-medium d-flex align-items-center text-cap
                            alize"
                        >
                          Private Seller
                        </h6>
                        {car?.owner?.phone && (
                          <div className="d-flex align-items-center">
                            <i className="fa text-primary me-2 fa-phone"></i>
                            <a
                              className="fs-7 text-primary"
                              href={"tel+" + car?.owner?.phone}
                            >
                              {car?.owner?.phone}
                            </a>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                      <div className="d-flex align-items-center">
                        {car?.car?.owner?.city && (
                          <>
                            <img
                              src="./img/pin.png"
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <h6 className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                              {car?.car?.owner?.city} (
                              {car?.car?.owner?.country})
                            </h6>
                          </>
                        )}
                      </div>
                      <div>
                        <p className="text-primary fs-7 pb-0 mb-0">
                          More <i className="fa fa-arrow-right"></i>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center px-3 py-2">
                      <button
                        onClick={() => setContactInfo(car?.bidder)}
                        className="btn btn-primary rounded-pill "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                      >
                        Contact Buyer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rounded-0">
              <div className="modal-body ">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="img/slide1.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide2.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide3.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0 py-1">
                <button
                  type="button"
                  className="btn btn-secondary rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header pb-0">
                <h4>Contact Details</h4>
              </div>
              <div className="modal-body text-center blue mt-3 contact_modal">
                <h5 className="fs-6 text-capitalized">
                  <b>Name - </b>
                  {contactInfo?.first_name} {contactInfo?.last_name}
                </h5>
                <h5 className="fs-6 text-capitalized">
                  <b>Contact - </b>
                  {contactInfo?.phone}
                </h5>
                <Link className="blue ">
                  <h5 className="fs-6 text-capitalized">
                    <b>Email - </b>
                    {contactInfo?.email}
                  </h5>
                </Link>
                <div className="mt-4">
                  <a
                    className="btn btn-primary me-2"
                    href={`tel:+${contactInfo?.phone}`}
                  >
                    <i className="fa fa-phone"></i>
                  </a>
                  <a
                    className="btn btn-info text-white"
                    href={`mailto:${contactInfo?.email}`}
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                </div>
              </div>

              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AppRequests;
