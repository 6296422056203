import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../Footer";
const Upgrade = () => {
  const navigate = useNavigate();
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);
  const navigateToCheckout = (plan) => {
    navigate("/dealer/checkout", { state: { plan } });
  };
  const plans = {
    monthly: [
      {
        code: "basic_monthly_plan",
        period:"month",
        highlight: false,
        name: "Dealer Basic",
        price: "40",
        carLimit:50,
        subtext:
          "Get started with an entry level advertising plan for your dealership.",
        features: ["1-50", "Dealer Dashboard"],
      },
      {
        code: "standard_monthly_plan",
        period:"month",
        highlight: true,
        name: "Dealer Standard",
        price: "65",
        carLimit:100,
        subtext:
          "Advertise all your inventory at a great price. Fits most business models.",
        features: ["51-100", "Dealer Dashboard", "Dealer to Dealer Messaging"],
      },
      {
        code: "unlimited_monthly_plan",
        period:"month",
        highlight: false,
        name: "Unlimited",
        price: "85",
        carLimit:1,
        subtext: "It offers exactly what it says. Unlimited car listings!",
        features: [
          "Unlimited Car Listing",
          "Dealer Dashboard",
          "Dealer to Dealer Messaging",
          "Detailed Reporting",
          "3rd Party Software Integration",
        ],
      },
    ],
    yearly: [
      {
        code: "basic_yearly_plan",
        period:"year",
        highlight: false,
        name: "Dealer Basic",
        price: "400",
        carLimit:700,
        subtext:
          "Get started with an entry level advertising plan for your dealership.",
        features: ["1-50", "Dealer Dashboard"],
      },
      {
        code: "standard_yearly_plan",
        period:"year",
        highlight: true,
        name: "Dealer Standard",
        price: "650",
        carLimit:1100,
        subtext:
          "Advertise all your inventory at a great price. Fits most business models.",
        features: ["51-100", "Dealer Dashboard", "Dealer to Dealer Messaging"],
      },
      {
        code: "unlimited_yearly_plan",
        period:"year",
        highlight: false,
        name: "Unlimited",
        price: "850",
        carLimit:1,
        subtext: "It offers exactly what it says. Unlimited car listings!",
        features: [
          "Unlimited Car Listing",
          "Dealer Dashboard",
          "Dealer to Dealer Messaging",
          "Detailed Reporting",
          "3rd Party Software Integration",
        ],
      },
    ],
  };
  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const handleToggle = () => {
    setSelectedPlan(selectedPlan === "monthly" ? "yearly" : "monthly");
  };
  return (
    <>
      <Navbar />
      <div className="new_page">
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap w-100 mb-3 px-2">
            <h3 className="fs-5 fw-bold mb-2">Carloca Plans</h3>
            <div className="d-flex flex-wrap">
              <Link
                to="/dealer/dashboard"
                className="btn btn-primary border-0 me-2 mb-2"
              >
                Back To Dashboard
              </Link>
            </div>
          </div>
          <div
            className="card shadow-0 rounded-0 p-2 py-4 mt-5"
            style={{ boxShadow: "unset" }}
          >
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-md-6 mb-5">
                    <h1 className="fs-3 fw-bold text-center">
                      Find Your Perfect Dealer Plan
                    </h1>
                    <p className="text-center " style={{ fontWeight: "100" }}>
                      Discover the ideal plan to fuel your business growth. Our
                      pricing options are carefully crafted to cater to car
                      advertising needs.
                    </p>
                    <div className="toggle-container">
                      <div
                        className={`toggle-btn ${
                          selectedPlan === "monthly" ? "monthly" : "yearly"
                        }`}
                        onClick={handleToggle}
                      >
                        <span
                          className={`toggle-text ${
                            selectedPlan === "monthly" ? "active" : ""
                          }`}
                        >
                          Monthly
                        </span>
                        <span
                          className={`toggle-text ${
                            selectedPlan === "yearly" ? "active" : ""
                          }`}
                        >
                          Yearly
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-11">
                <div className="row justify-content-center">
                  {plans[selectedPlan].map((plan, index) => (
                    <div className="col-md-4 mt-3" style={{ minHeight: "26rem" }}>
                      <div
                        className={`card h-100 plancard ${
                          plan?.highlight ? "active" : ""
                        }`}
                        key={plan?.code}
                      >
                        <div className="card-body position-relative">
                          <h4 className="fs-4 fw-bold plan_heading">
                            {plan?.name}
                          </h4>
                          <p className="fw-lighter  subtext">{plan?.subtext}</p>
                          <div className="d-flex align-items-center">
                            <span className="plan_price fs-3 me-2 fw-bolder">
                              &pound;{plan?.price}
                            </span>
                            <span className="month">per {selectedPlan}</span>
                          </div>
                          <hr />
                          <ul className="list-unstyled">
                            {plan.features.map((feature, i) => (
                              <li className="list-group-item mt-2">
                                <i className="fa fa-check me-2"></i>
                                 {feature}
                              </li>
                            ))}
                          </ul>
                          <button
                            onClick={() => navigateToCheckout(plan)}
                            className="btn btn-outline-primary mx-auto position-absolute"
                            style={{ width: "90%", bottom: "5%" }}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
              </div>
              <div className="col-md-6 mt-3">
                 <p className="text-center" style={{fontSize:"13px"}}>If your listing requirements have changed then you can easily upgrade or downgrade your plan to accommodate the number of cars you need to list.</p>
                
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Upgrade;
