import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../Footer";
import axios from "axios";
import DataTable from "react-data-table-component";
const AllMyOffers = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [loading2, setLoading2] = useState(false);
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");
  useEffect(() => {
    if (storedUserPreferences === null) {
      Swal.fire({
        title: "Unauthorized",
        text: "You need to be logged in to access this page",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        } else {
          navigate("/");
        }
      });
      return;
    } else if (storedUserPreferences?.role !== "dealer") {
      Swal.fire({
        title: "Unauthorized Access",
        text: "Only dealer can access this!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Back To Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
      return;
    }
  }, []);

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };

  const getAllMyCar = async () => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/get/offer/bids",
        {
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setData(response?.data?.biddings?.pending);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    getAllMyCar();
  }, []);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const handleSelectedRowsChange = ({ selectedRows }) => {
    const ids = selectedRows.map((row) => row.id);
    setSelectedRowIds(ids);
  };
  const deleteMyOffers = async () => {
    try {
      setLoading2(true);
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/delete/all-selected-bids",
        {
          bid_ids: selectedRowIds,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        setLoading2(false);
        getAllMyCar();
      } else {
        setLoading2(false);
        alert(response?.data?.message);
      }
    } catch (error) {
      setLoading2(false);
      console.log("An error occurred while fetching data.", error);
    }
  };

  const columnsData = [
    {
      name: "Car",
      selector: (row) => row?.car?.make + "," + row?.car?.model,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row?.bidder?.first_name,
      sortable: true,
      wrap: true
    },
    {
      name: "Email",
      selector: (row) => row?.bidder?.email,
      sortable: true,
      width:"200px",
      wrap: true
    },
    {
      name: "Phone",
      selector: (row) => row?.bidder?.phone,
      wrap: true
    },
    {
      name: "Enquiry Type",
      selector: (row) => row?.bid_request,
      wrap: true
    },
    {
      name: "Price Offered",
      selector: (row) => formatNumber(row?.amount),
      width: "80px",
    },
    {
      name: "Date Submitted",
      selector: (row) => row.created_at_human,
      sortable: true,
      wrap: true
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          onClick={() => handleDelete(row.id)}
          className="btn text-danger btn-sm"
        >
          <i className="fa fa-trash"></i>
        </button>
      ),
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [filterText, setFilterText] = useState("");

  const filteredItems = data.filter(
    (item) =>
      (item?.car?.make &&
        item?.car?.make.toLowerCase().includes(filterText.toLowerCase())) ||
      (item?.car?.model &&
        item?.car?.model.toLowerCase().includes(filterText.toLowerCase())) ||
      (item?.car?.body_type &&
        item?.car?.body_type
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item?.car?.color &&
        item?.car?.color.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponent = (
    <input
      type="text"
      placeholder="Search..."
      value={filterText}
      onChange={(e) => setFilterText(e.target.value)}
      style={{
        marginBottom: "10px",
        padding: "5px 10px",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
  );
  const deleteMyBid = async (id) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/delete/bid",
        {
          bid_id: id,
          owner_id: storedUserPreferences?.id,
        }
      );

      if (response?.data?.status) {
        getAllMyCar();
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  const handleDelete = (id) => {
    const userConfirmed = window.confirm(
      "are you sure you want to delete the bid data ?"
    );
    if (userConfirmed) {
        deleteMyBid(id);
    }
  };

  return (
    <>
      <Navbar />
      <div className="new_page">
        <div
          className="approved_cars all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap w-100 mb-3 px-2">
            <h3 className="fs-5 fw-bold mb-2">Offers</h3>
            <div className="d-flex flex-wrap">
              <button
                type="button"
                onClick={deleteMyOffers}
                disabled={loading2}
                className="btn btn-primary border-0 mb-2 me-2"
              >
                {loading2 ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Processing...
                  </>
                ) : (
                  "Delete"
                )}
              </button>
              <Link
                to="/dealer/dashboard"
                className="btn btn-primary border-0 me-2 mb-2"
              >
                Back To Dashboard
              </Link>
            </div>
          </div>
          <div className="card p-2 mt-5">
            {data.length === 0 ? (
              <div></div>
            ) : (
              <DataTable
                columns={columnsData}
                data={filteredItems}
                selectableRows
                onSelectedRowsChange={handleSelectedRowsChange}
                pagination
                responsive
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponent}
                highlightOnHover
                customStyles={{
                  header: {
                    style: {
                      fontSize: "18px",
                      fontWeight: "bold",
                    },
                  },
                  rows: {
                    style: {
                      fontSize: "14px",
                    },
                  },
                }}
              />
            )}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AllMyOffers;
