import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Navbar from "./Navbar";
import Footer from "../Footer";
import CarDetailSlider from "./CarDetailSlider";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Heart from "react-heart";

import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ReadMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="carDescription">
      <div dangerouslySetInnerHTML={{ __html: renderText() }} />
      {text.length > maxLength && (
        <span
          onClick={toggleReadMore}
          style={{ color: "#00889c", cursor: "pointer" }}
        >
          {isExpanded ? " Read Less" : " Read More"}
        </span>
      )}
    </div>
  );
};
const Cardetail = () => {
  const [data, setData] = useState([]);
  const [unlock, setUnlock] = useState(false);
  const [isMakeOffer, setMakeOffer] = useState(false);
  const [isMyCar, setIsMyCar] = useState(false);
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerPhone, setBuyerPhone] = useState("");
  const [buyerRequest, setBuyerRequest] = useState("");
  const [bidValue, setBidValue] = useState("");
  const [errors, setErrors] = useState([]);
  const [errorData, setErrorData] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = new useNavigate();
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  // Validation states
  const [touchedFields, setTouchedFields] = useState({
    buyerName: false,
    buyerEmail: false,
    buyerPhone: false,
    bidValue: false,
    buyerRequest: false,
  });

  // Handle validation on blur (field loses focus)
  const handleBlur = (field) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
  };

  // Validate if field is empty and touched
  const getFieldClass = (fieldValue, fieldName) => {
    return !fieldValue && touchedFields[fieldName] ? "is-invalid" : "";
  };
  const location = useLocation();

  // const carDetails = location.state && location.state.carDetails;
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  // const [MyCredit, setMyCredit] = useState(0);
  const [likeStatus, setLikeStatus] = useState(false);

  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  async function fetchedDetail(slug) {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/car/detail",
        {
          user_id: storedUserPreferences?.id,
          slug: slug,
        }
      );
      if (response?.data?.status) {
        setData(response?.data?.data);
        var carData = response?.data?.data;
        // console.log(response?.data?.data);
        if (carData?.isFav === true) {
          setLikeStatus(true);
        } else {
          setLikeStatus(false);
        }
        if (carData?.isOffered === true) {
          setMakeOffer(true);
        } else {
          setMakeOffer(false);
        }
        if (storedUserPreferences?.id === carData?.owner_id) {
          setIsMyCar(true);
        } else {
          setIsMyCar(false);
        }
      } else {
        console.log(response?.data);
        setErrorData(response?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setErrorData(
        error.response?.data?.message || "Failed to fetch car details"
      );
      setLoading(false);
    }
  }
  useEffect(() => {
    // alert();
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get("car");

    if (!slug) {
      setErrorData("Car slug is missing in the URL.");
      setLoading(false);
      return;
    }
    fetchedDetail(slug);
    setBuyerEmail(storedUserPreferences?.email);
    setBuyerPhone(storedUserPreferences?.phone);
    setBuyerName(storedUserPreferences?.first_name);
  }, [location.search]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  const handleClick = () => {
    if (!storedUserPreferences?.id) {
      Swal.fire({
        title: "",
        text: "You need to be logged in to unlock a car.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889C",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      return;
    }
    if (!buyerPhone) {
      toast.error("Please fill in the phone number.", optionToast);

      return; // Stop further execution if phone number is missing
    }
    if (!buyerEmail) {
      toast.error("Please fill in the email address.", optionToast);

      return; // Stop further execution if email address is missing
    }
    if (!isValidEmail(buyerEmail)) {
      toast.error("Please enter a valid email address.", optionToast);

      return; // Stop further execution if email format is invalid
    }
    if (!(bidValue >= 0)) {
      toast.error("Please enter your offer value", optionToast);
      return;
    }
    saveBidData();
  };

  const liveApi = "https://stagingsite.carloca.co.uk/apis/public/api/add/bidding";
  //   console.log(data);
  const saveBidData = async () => {
    setMakeOffer(false);
    try {
      axios
        .post(liveApi, {
          user_id: storedUserPreferences?.id,
          car_id: data?.id,
          name: buyerName,
          email: buyerEmail,
          phone: buyerPhone,
          bid_request: buyerRequest,
          amount: bidValue,
        })
        .then((res) => {
          //   console.log(res?.data);
          //   alert(data?.id);
          if (res?.data?.status) {
            toast.success(res?.data?.message, optionToast);
            setUnlock(false);
            setBidValue("");
            setBuyerEmail("");
            setBuyerEmail("");
            setBuyerName("");
            setBuyerPhone("");
            setBuyerRequest("");
            setMakeOffer(true);
          } else {
            console.log(res?.data?.errors);
            setErrors(res?.data?.errors);
            toast.error(res?.data?.message, optionToast);
          }
        });
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function unlockOffer() {
    if (!storedUserPreferences?.id) {
      Swal.fire({
        title: "",
        text: "You need to be logged in to unlock a car.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00889c",
        cancelButtonColor: "#d33",
        confirmButtonText: "Log In Now",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      return;
    }
    let MyCredit = 0;
    const fetchCredit = async () => {
      try {
        const response = await axios.post(
          "https://stagingsite.carloca.co.uk/apis/public/api/get/credits",
          {
            user_id: storedUserPreferences?.id,
          }
        );
        MyCredit = response?.data?.total_credit;
        if (MyCredit < 1) {
          Swal.fire({
            title: "",
            text: "You don't have enough credits to unlock this car.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#00889c",
            confirmButtonText: "Add Credits",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/buyplan");
            }
          });
          return;
        }
        setUnlock(!unlock);
      } catch (error) {
        console.log("An error occurred while fetching data.", error);
      }
    };
    fetchCredit();
  }
  const back = () => {
    navigate(-1);
  };

  const likeDislike = async (user_id, card_id) => {
    try {
      const responsed = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/like-dislike",
        {
          user_id: user_id,
          car_id: card_id,
        }
      );
      // console.log(responsed);
      if (responsed?.data?.status) {
        toast.success(responsed?.data?.message, optionToast);
      } else {
        toast.error(responsed?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const handleLike = (cardId) => {
    if (storedUserPreferences !== null) {
      setLikeStatus(!likeStatus);
      likeDislike(storedUserPreferences?.id, cardId);
    } else {
      toast.warning("Please login to add favorite car!", optionToast);
    }
  };
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  return (
    <>
      <Navbar />
      <div className="new_page" style={{ minHeight: "100vh" }}>
        {/* <div className="main_banner "></div>
         <SearchArea/> */}
        {data?.min_price ? (
          <div className="detail_card container w-100 mt-5 py-5">
            <h2 className="fw-bold text-center mb-3">Car Details</h2>
            <div className="col-lg-7 col-md-9 col-sm-11 mx-auto">
              <div className="text-end">
                <button className="btn btn-primary mb-3" onClick={back}>
                  <i className="fa-solid fa-arrow-left me-2"></i>
                  Back
                </button>
              </div>

              <div className="card border position-relative">
                <div className="card-body">
                  <div className="position-relative w-100">
                    <CarDetailSlider data={data?.gallery} image={data?.image} />
                    <div
                      style={{
                        position: "absolute",
                        top: "7px",
                        left: "7px",
                        zIndex: "1",
                        height: "26px",
                        width: "26px",
                      }}
                    >
                      <Heart
                        isActive={likeStatus}
                        onClick={() => {
                          handleLike(data.id);
                        }}
                        animationTrigger="both"
                        inactiveColor="#00889c"
                        activeColor="#00889C"
                        animationDuration={0.2}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div>
                      <div className="ratings fs-7 d-flex">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <span className="ms-2 text-muted fs-7">(4.6)</span>
                      </div>
                      <a
                        href="h"
                        className="text-dark text-uppercase fs-7 mt-3 d-block"
                      >
                        See Reviews
                      </a>
                    </div>
                    {data?.owner?.city && (
                      <div>
                        <h6 className="fs-7 text-muted">
                          <i className="fa fa-location-dot"></i>
                          <span className="ms-2">
                            {data?.owner?.city} ({data?.owner?.country})
                          </span>
                        </h6>
                      </div>
                    )}
                  </div>

                  <div className="border rounded-3 py-3 px-2  mb-3">
                    <ul className="p-0 d-flex justify-content-between flex-wrap gap-2 carinfo  m-0 ">
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Make
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.make}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Model
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.model}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Year
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.year}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Color
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.color}
                        </p>
                      </li>
                      <li>
                        <h6 className="fw-bold" style={{ fontSize: "13px" }}>
                          Body Type
                        </h6>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data?.body_type}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-dark fw-bold">Price Range:</h6>
                    <h6 className="fw-bold price">
                      £{formatEuroAmount(data?.min_price)} - £
                      {formatEuroAmount(data?.max_price)}
                    </h6>
                  </div>
                  <hr className="hr" />
                  <div className="d-flex car_info justify-content-between flex-wrap">
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/automatic.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.transmission_type}
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/speedometer.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {formatNumber(data?.current_mileage)}
                    </h6>
                    <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/fuel.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.fuel_type}
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/engine.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {convertCcToLiters(data?.engine_capacity)}L
                    </h6>
                    <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                      <img
                        src="./img/seating.png"
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {data?.seating_capacity}
                    </h6>
                  </div>
                  <hr className="hr" />

                  {data?.owner?.role === "dealer" ? (
                    <div className="mb-2 d-flex justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div
                          style={{
                            height: "2.4rem",
                            width: "2.4rem",
                            border: "2px solid #ddd",
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                          className="me-2 text-capitalize "
                        >
                        
                          <img
                            src={(data?.owner?.profile!=="" && data?.owner?.profile!=null)?`https://stagingsite.carloca.co.uk/apis/public/uploads/users/${data?.owner?.profile}`:`https://stagingsite.carloca.co.uk/apis/public/uploads/user.jpg`}
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                          />
                        </div>
                        <h6 className="my-0 text-primary fs-7 text-capitalize ">
                          {data?.owner?.first_name} {data?.owner?.last_name}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fa text-primary me-2 fa-phone"></i>
                        <a
                          className="fs-7 text-primary"
                          href={"tel+" + data?.owner?.phone}
                        >
                          {data?.owner?.phone}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-2 d-flex ">
                      <h6 className="text-primary   fw-medium d-flex align-items-center text-capalize">
                        Private Seller
                      </h6>
                    </div>
                  )}

                  <h5>Description</h5>
                  <div className="moreTextshow">
                    <ReadMore text={data?.specifications} maxLength={100} />
                  </div>
                </div>
              </div>
              <div className={`text-center my-3 ${isMyCar ? "d-none" : ""}`}>
                <h5 className="text-dark fw-bold">Contact the Seller</h5>
              </div>
              <div className="text-center my-4">
                {isMakeOffer ? (
                  <div
                    className={`d-flex flex-column justify-content-center align-items-center ${
                      isMyCar ? "d-none" : ""
                    }`}
                  >
                    <button
                      type="button"
                      disabled="true"
                      className="decided-btn disabled"
                      style={{ cursor: "not-allowed", opacity: "0.4" }}
                    >
                      {unlock ? "Decide Later" : "Unlock This Car"}
                    </button>
                    <small className="mt-2 d-block fw-medium ">
                      You have already made an offer on this car. Your offer is
                      being reviewed by the seller.
                    </small>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={unlockOffer}
                    className={`decided-btn ${isMyCar ? "d-none" : ""}`}
                  >
                    {unlock ? "Decide Later" : "Unlock This Car"}
                  </button>
                )}
              </div>
              {unlock ? (
                <div className="col-12 mt-4">
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <div className="row">
                        {!errors || typeof errors !== "object"
                          ? ""
                          : Object.keys(errors).map((key) =>
                              errors[key].map((error, index) => (
                                <small
                                  key={index}
                                  className="col-12 text-center text-danger mb-2"
                                >
                                  {error}
                                </small>
                              ))
                            )}
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <input
                              className={`form-control ${getFieldClass(
                                buyerName,
                                "buyerName"
                              )}`}
                              name="buyer_name"
                              placeholder="Buyer Name"
                              type="text"
                              value={buyerName}
                              onChange={(e) => setBuyerName(e.target.value)}
                              onBlur={() => handleBlur("buyerName")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <input
                              className={`form-control ${getFieldClass(
                                buyerEmail,
                                "buyerEmail"
                              )}`}
                              name="buyer_email"
                              placeholder="Email Address"
                              type="email"
                              value={buyerEmail}
                              onChange={(e) => setBuyerEmail(e.target.value)}
                              onBlur={() => handleBlur("buyerEmail")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <input
                              className={`form-control ${getFieldClass(
                                buyerPhone,
                                "buyerPhone"
                              )}`}
                              name="buyer_phone"
                              placeholder="Phone Number"
                              type="tel"
                              value={buyerPhone}
                              onChange={(e) => setBuyerPhone(e.target.value)}
                              onBlur={() => handleBlur("buyerPhone")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group inputgroup mb-4">
                            <span className="input-group-text">
                              <i className="fa-solid fa-sterling-sign"></i>
                            </span>
                            <input
                              className={`form-control ${getFieldClass(
                                bidValue,
                                "bidValue"
                              )}`}
                              name="price_offered"
                              placeholder="Price Offered"
                              type="number"
                              value={bidValue}
                              onChange={(e) => setBidValue(e.target.value)}
                              onBlur={() => handleBlur("bidValue")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <select
                              className={`form-control ${getFieldClass(
                                buyerRequest,
                                "buyerRequest"
                              )}`}
                              name="buyer_request"
                              value={buyerRequest}
                              onChange={(e) => setBuyerRequest(e.target.value)}
                              onBlur={() => handleBlur("buyerRequest")}
                            >
                              <option value="">Please select</option>
                              <option value="general enquiry">
                                General enquiry
                              </option>
                              <option value="looking to buy now">
                                Looking to buy now
                              </option>
                              <option value="looking to buy in the future">
                                Looking to buy in the future
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="text-center my-3">
                          <p style={{ fontWeight: "500" }}>
                            If the seller doesn’t respond within 7 days then
                            your offer will be automatically declined and no
                            credit will be taken
                          </p>
                          <button
                            onClick={handleClick}
                            type="button"
                            className="decided-btn mt-4"
                          >
                            Submit Your Offer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div
            className="text-center w-100 mt-5 d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Cardetail;
