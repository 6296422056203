import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../Footer";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const ListDetails = () => {
  const navigate = useNavigate();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [currentMileage, setCurrentMileage] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [carData, setCarData] = useState([]);
  const [isCarData, setCarDataAv] = useState(false);
  const [isCarAccept, setIsCarAccept] = useState(true);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [modelVarient, setModelVarient] = useState("");
  const [year, setYear] = useState("");
  const [fuel_type, setFuelType] = useState("");
  const [color, setColor] = useState("");
  const [body_type, setBodyType] = useState("");
  const [transmission_type, setTransmissionType] = useState("");
  const [seating_capacity, setSeatingCapacity] = useState("");
  const [carDataDetail, setcarDataDetail] = useState([]);
  const [carEngineCapacity, setcarEngineCapacity] = useState();
  const [checking, setChecking] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [preview, setPreview] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isAbleSubmit, setIsAbleSubmit] = useState(true);
  const [errors, setErrors] = useState({ mileage: "", email: "", phone: "" });
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleEditorChange = (value) => {
    var clearHtml=value.replace(/<p><br><\/p>/g, '').trim();
    setSpecifications(clearHtml);
  };

  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  useEffect(() => {
    // const storedUserPreferences = getDataFromlocalStorage('userData');
    if (storedUserPreferences === null) {
      toast.error("Please login first to upload", optionToast);
      navigate("/login");
    }
    setEmail(storedUserPreferences?.email);
    setTel(storedUserPreferences?.phone);
  }, []);

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages([...images, ...selectedImages]);
  };
  const removeImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const addCarToList = () => {
    if (isCarData) {
      const formData = {
        owner_id: storedUserPreferences?.id,
        registration_number: registrationNumber,
        min_price: minPrice,
        max_price: maxPrice,
        make: make,
        model: model,
        model_varient: modelVarient,
        year: year,
        fuel_type: fuel_type,
        body_type: body_type,
        transmission_type: transmission_type,
        color: color,
        seating_capacity: seating_capacity,
        specifications: specifications,
        phone: tel,
        email: email,
        latitude: 40.7128,
        longitude: -74.006,
        images: images,
        previewImg: previewImg,
        current_mileage: currentMileage,
        engine_capacity: carEngineCapacity,
        carData: carDataDetail,
      };

      axios
        .post("https://stagingsite.carloca.co.uk/apis/public/api/car/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res?.data);
          setLoading(false);

          if (res?.data?.status) {
            setRegistrationNumber("");
            setCurrentMileage("");
            setMinPrice("");
            setMaxPrice("");
            setSpecifications("");
            setImages([]);
            setTel("");
            setEmail("");
            navigate("/thankyou", { state: { data: res?.data } });
            setCarDataAv(false);
          } else {
            toast.error(res?.data?.message, optionToast);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please verify your car registration number!", optionToast);
    }
  };
  const getCarData = () => {
    setChecking(true);
    setIsAbleSubmit(true);
    const apiKey = "102052c6-d059-4e04-b51d-0617eec8bd7c";
    axios
      .get(
        "https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=" +
          apiKey +
          "&user_tag=&key_VRM=" +
          registrationNumber +
          ""
      )
      .then((res) => {
        // console.log(res?.data);

        setChecking(false);

        if (res?.data?.Response?.StatusCode === "Success") {
          // console.log(res?.data?.Response);
          setCarData(res?.data?.Response?.DataItems);
          const carDetail = res?.data?.Response?.DataItems;
          setMake(carDetail?.VehicleRegistration?.Make);
          setModel(carDetail?.VehicleRegistration?.Model);
          setModelVarient(carDetail?.SmmtDetails?.ModelVariant);
          setBodyType(carDetail?.SmmtDetails?.BodyStyle);
          setFuelType(carDetail?.VehicleRegistration?.FuelType);
          setColor(carDetail?.VehicleRegistration?.Colour);
          setTransmissionType(carDetail?.VehicleRegistration?.TransmissionType);
          setYear(carDetail?.VehicleRegistration?.YearOfManufacture);
          setSeatingCapacity(carDetail?.VehicleRegistration?.SeatingCapacity);
          setcarEngineCapacity(carDetail?.VehicleRegistration?.EngineCapacity);
          setcarDataDetail(carDetail);
          setIsAbleSubmit(true);
          setCarDataAv(true);
          setIsCarAccept(true);
        } else {
          setCarDataAv(false);
          setIsCarAccept(true);
          setIsAbleSubmit(true);
          setCarData([]);
          setMake("");
          setModel("");
          setBodyType("");
          setFuelType("");
          setColor("");
          setTransmissionType("");
          setYear("");
          setSeatingCapacity("");
          setcarEngineCapacity("");
          setModelVarient("");
          setcarDataDetail("");
          toast.error("Registration number is wrong!", optionToast);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePreviewImgChange = async (e) => {
    const file = e.target.files[0];
    setPreviewImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setUploading(false);
    } else {
      setUploading(false);
    }
  };
  
  const isCarRegistrationExist = () => {
    setChecking(true);

    const formData = {
      registration_number: registrationNumber,
    };
    axios
      .post("https://stagingsite.carloca.co.uk/apis/public/api/car/data", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setChecking(false);
        if (res?.data?.code === 101) {
          getCarData();
        } else {
          toast.error(res?.data?.message, optionToast);
          setChecking(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation logic
    if (!tel) {
      toast.error("Please fill in the phone number.", optionToast);

      return; // Stop further execution if phone number is missing
    }
    if (!email) {
      toast.error("Please fill in the email address.", optionToast);

      return; // Stop further execution if email address is missing
    }
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.", optionToast);

      return; // Stop further execution if email format is invalid
    }
    if (!registrationNumber) {
      toast.error("Please fill in the registration number.", optionToast);

      return;
    }
    if (!currentMileage) {
      toast.error("Please fill in the current mileage.", optionToast);

      return; // Stop further execution if current mileage is missing
    }
    if (!minPrice) {
      toast.error("Please fill in the minimum price.", optionToast);

      return; // Stop further execution if minimum price is missing
    }
    if (!maxPrice) {
      toast.error("Please fill in the maximum price.", optionToast);

      return; // Stop further execution if maximum price is missing
    }
    if (!specifications) {
      toast.error("Please fill in the specifications.", optionToast);

      return;
    }

    if (images.length === 0) {
      toast.error("Please add the images.", optionToast);
      return;
    }
    if (!isChecked) {
      toast.error("Please accept our terms & conditions", optionToast);
      return;
    }
    setLoading(true);

    // Call addCarToList only if all fields are valid
    addCarToList();
  };

  const validateMileage = (value) => {
    if (/^\d*$/.test(value)) {
      setErrors((prev) => ({ ...prev, mileage: "" }));
      setCurrentMileage(value);
    } else {
      setErrors((prev) => ({
        ...prev,
        mileage: "Please enter a valid current mileage (Like 20, 39, etc).",
      }));
    }
  };

  const validateEmail = (value) => {
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };

  const validatePhone = (value) => {
    setTel(value);
    const phoneRegex = /^\+?\d{0,15}$/;
    if (value && !phoneRegex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        phone:
          "Please enter a valid phone number (10 to 15 digits, with optional +).",
      }));
    } else if (value.length >= 10 && value.length <= 15) {
      setErrors((prev) => ({ ...prev, phone: "" }));
    } else if (value.length > 0 && (value.length < 10 || value.length > 15)) {
      setErrors((prev) => ({
        ...prev,
        phone: "Phone number must be between 10 and 15 digits.",
      }));
    }
  };
  return (
    <>
      <Navbar />
      <div className="new_page w-100 container-fluid">
        <div className="list_details add_car_container row  shadow-0  p-sm-4 p-0 mb-5 d-flex flex-column justify-content-center align-items-center">
          <form
            action=""
            className="col-md-8 d-flex flex-column justify-content-center mt-5"
          >
            <div className="row border-0">
              <div className="col-md-12">
                <div className="ps-2">
                  <h4 className="fs-4 fw-semibold mt-2 poppins-semibold">
                    Add Your Car
                  </h4>
                  {/* <p className="poppins-medium mt-3 fs-sm">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="search-main  mt-md-3 mt-3">
              <div className="card glassmorphism-1 shadow-0">
                <div className="card-body w-100 d-flex align-items-center justify-content-around">
                  <div className="input-group py-1  ">
                    <input
                      type="text"
                      placeholder="Enter Reg Here"
                      name="registration"
                      id="registration"
                      className="form-control form-control-lg"
                      value={registrationNumber}
                      onChange={(e) =>
                        setRegistrationNumber(
                          e.target.value.replace(/\s+/g, "")
                        )
                      }
                    />
                    <button
                      type="button"
                      onClick={isCarRegistrationExist}
                      disabled={checking}
                      className="decided-btn input-group-btn"
                    >
                      {checking ? "Checking..." : "Check"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isCarData && (
              <div className="col-md-12 ">
                <div className="row border-0 justify-content-center">
                  <div className="col-sm-12 col-md-8">
                    <div className="ps-2">
                      <h4 className="fs-4 fw-semibold mt-5 mb-3 poppins-semibold">
                        Is This Your Car?
                      </h4>
                    </div>
                    <div className="card glassmorphism-1 ">
                      <div className="card-body p-md-5 p-3">
                        <div className="card border-0 rounded-3 shadow-lg">
                          <div className="card-body card_detail_list">
                            <ul>
                              <li>
                                <span className="list-heading">Make:</span>
                                <span className="list-text">{make}</span>
                              </li>
                              <li>
                                <span className="list-heading">Model:</span>
                                <span className="list-text">{model}</span>
                              </li>
                              <li>
                                <span className="list-heading">Year:</span>
                                <span className="list-text">{year}</span>
                              </li>
                              <li>
                                <span className="list-heading">Colour:</span>
                                <span className="list-text">{color}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {isCarAccept && (
                          <div className="d-flex pt-3 justify-content-around">
                            <button
                              onClick={() => {
                                setIsCarAccept(false);
                                toast.success(
                                  "Thanks for confirmation.",
                                  optionToast
                                );
                                setIsAbleSubmit(false);
                                const infoElement =
                                  document.querySelector("#info_about");
                                if (infoElement) {
                                  infoElement.scrollIntoView({
                                    behavior: "smooth",
                                  });
                                }
                              }}
                              type="button"
                              className="decided-btn"
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="decided-btn"
                              onClick={() => {
                                setCarDataAv(false);
                              }}
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <h3 className="text-start mt-4">Contact Details</h3>

            <p>
              Tell us about the specifications of your car, have you made any
              moderations on your car which makes it stand out from others?
            </p>
            <ul className="bullet_points car_upload_disc py-3">
              <li>
                <span className="large-disc">●</span> We recommend you don’t put
                any personal information on/visible.
              </li>
              <li>
                {" "}
                <span className="large-disc">●</span> If possible, cover up your
                number plate.
              </li>
              <li>
                <span className="large-disc">●</span> Every 3 months you will
                receive an email asking if you still want your car advertised on
                Carloca.
              </li>
              <li>
                <span className="large-disc">●</span> When meeting up with a
                customer, we recommend meeting in a public place, rather than
                your home.
              </li>
            </ul>

            <div id="info_about" className="row">
              <div className="col-md-6 pt-md-1 ">
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Tel"
                    value={tel}
                    onChange={(e) => validatePhone(e.target.value)}
                    style={{
                      borderColor: errors.phone ? "red" : "",
                    }}
                  />
                  <i className="fa-solid fa-phone"></i>
                </div>
                {errors.phone && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.phone}
                  </div>
                )}
              </div>
              <div className="col-md-6 pt-md-1 pt-4">
                <div className="position-relative">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => validateEmail(e.target.value)}
                    style={{
                      borderColor: errors.email ? "red" : "",
                    }}
                  />
                  <i className="fa-solid fa-envelope"></i>
                </div>
                {errors.email && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <h3 className="py-3">About The Car</h3>
            <div className="row">
              <div className="col-md-12 mb-3">
                <input
                  type="text"
                  placeholder="Current Mileage"
                  value={currentMileage}
                  onChange={(e) => validateMileage(e.target.value)}
                  style={{
                    borderColor: errors.mileage ? "red" : "",
                  }}
                />
                {errors.mileage && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.mileage}
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <select
                  className="form-select"
                  aria-label="Min Price"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                >
                  <option>Min Price</option>
                  <option value="0">£0</option>
                  <option value="5000">£5,000</option>
                  <option value="10000">£10,000</option>
                  <option value="20000">£20,000</option>
                  <option value="30000">£30,000</option>
                  <option value="40000">£40,000</option>
                  <option value="50000">£50,000</option>
                  <option value="60000">£60,000</option>
                  <option value="70000">£70,000</option>
                  <option value="80000">£80,000</option>
                  <option value="90000">£90,000</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <select
                  className="form-select"
                  aria-label="Max Price"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                >
                  <option>Max Price</option>
                  <option value="5000">£5,000</option>
                  <option value="10000">£10,000</option>
                  <option value="20000">£20,000</option>
                  <option value="30000">£30,000</option>
                  <option value="40000">£40,000</option>
                  <option value="50000">£50,000</option>
                  <option value="60000">£60,000</option>
                  <option value="70000">£70,000</option>
                  <option value="80000">£80,000</option>
                  <option value="90000">£90,000</option>
                  <option value="100000">£100,000</option>
                </select>
              </div>
              <div className={`col-12 mb-3 `}>
                <ReactQuill
                  theme="snow"
                  placeholder="Write Specifications"
                  value={specifications}
                  onChange={handleEditorChange}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <h5 className="fw-bold">Preview Image</h5>
              <div className="col-sm-4 col-6 ">
                <img
                  src={
                    preview
                      ? preview
                      : `img/noimg.jpeg`
                  }
                  alt=""
                  style={{ width: "100%" }}
                  className="rounded-2"
                />
                <label
                  className="btn my-2 btn-primary btn-sm "
                  htmlFor="prevfile"
                  disabled={uploading}
                >
                  <i className="fa fa-edit"></i>
                </label>
                {uploading && <p>Uploading...</p>}
                <input
                  id="prevfile"
                  className="d-none"
                  type="file"
                  name="prevfile"
                  accept="image/*"
                  onChange={handlePreviewImgChange}
                />
              </div>
              <h5 className="fw-bold mt-3">Gallery Image</h5>
              <div className="col-12 mb-3">
                <div className="border text-center bg-white py-5">
                  <input
                    id="upload"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="upload">
                    <img
                      style={{ width: "80px", cursor: "pointer" }}
                      src="img/gallery_icon.png"
                      alt=""
                    />
                  </label>
                </div>
              </div>
              <div className="col-12 mb-3">
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Car ${index + 1}`}
                      style={{ width: "100px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => removeImage(index)}
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="col-12 mb-3">
                <div className="form-check blue d-flex px-0 ml-0 justify-content-start align-items-center">
                  <input
                    id="tandc"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="input-checkbox me-3"
                    style={{ height: 25, width: 25 }}
                    type="checkbox"
                  />
                  <label htmlFor="tandc" style={{ fontSize: 16 }}>
                    Accept our{" "}
                    <Link to="/term-and-condition" className="blue p-0 m-0">
                      Terms & Conditions
                    </Link>{" "}
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary text-center"
                  disabled={isAbleSubmit || loading} // Disable button during loading
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Uploading...
                    </>
                  ) : (
                    "List My Car"
                  )}
                </button>
                <small className="text-primary mt-2">
                  {isAbleSubmit ? "Please verify your reg no." : ""}
                </small>
              </div>
            </div>
          </form>
        </div>

        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default ListDetails;
