import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const ProfileSetting = () => {
  const optionToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };
  const [data, setData] = useState();
  const location = useLocation();
  const [userId, setUserId] = useState();
  const [file, setFile] = useState("");
  const baseUrl = "https://stagingsite.carloca.co.uk/apis/public/uploads/users/";

  const [editing, setEditing] = useState(false);

  const [editedData, setEditedData] = useState({
    email: data?.data?.email || "",
    phone: data?.data?.phone || "",
    addressFirst: data?.data?.address_first || "",
    addressSecond: data?.data?.address_second || "",
    city: data?.data?.city || "",
    country: data?.data?.country || "",
    postcode: data?.data?.postcode || "",
    address: data?.data?.address || "",
    cardNo: data?.data?.bank?.card_number || "",
    expiry: data?.data?.bank?.expiry || "",
    cvv: data?.data?.bank?.cvv || "",

    // Set the initial value for address
  });

  const handleEditClick = () => {
    setEditing(true);
  };
  // const handleEditAcClick = () => {
  //   setEditingAccount(true);
  // };
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };

  // Example usage:
  const storedUserPreferences = getDataFromlocalStorage("userData");
  const handleInputChange = (field, value) => {
    // Update the state for the edited data
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // console.log(editedData, "sdsdsddsddd");
  };

 

  const handleProfile = (event) => {
    // Assuming you have a function to process the file data here
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    // Automatically call handleProfileData after file data is obtained
    handleProfileData(selectedFile);
  };
  const saveDataTolocalStorage = (key, data) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  };
  const updateProfileDetails = async (
    userId,
    email,
    phone,
    address,
    address1,
    address2,
    city,
    country,
    postcode
  ) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/update/profile",
        {
          user_id: storedUserPreferences?.id,
          email: email,
          phone: phone,
          address: address,
          addressFirst: address1,
          addressSecond: address2,
          city: city,
          country: country,
          postcode: postcode,
          first_name: data?.data?.first_name,
        }
      );
      // setData(response?.data)
      console.log(response?.data, "kkkssss");
      if (response?.data?.status) {
        // If the request was successful, update the user's profile details in the local storage
        toast.success(response?.data?.message, optionToast);
        saveDataTolocalStorage("userData", response?.data?.data);
        fetchData(userId);
      } else {
        toast.error(response?.data?.message, optionToast);
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  // const updateUserBankDetails = async (card_number, expiry, cvv) => {

  //   try {
  //     const response = await axios.post(
  //       "https://stagingsite.carloca.co.uk/apis/public/api/user/add/update/card",
  //       {
  //         user_id: storedUserPreferences?.id,
  //         card_number: card_number,
  //         expiry: expiry,
  //         cvv: cvv,
  //       }
  //     );

  //     if (response?.data?.status) {
  //       toast.success(response?.data?.status, optionToast);
  //       fetchData(userId);
  //     } else {
  //       toast.error(response?.data?.status, optionToast);
  //     }
  //   } catch (error) {
  //     console.log("An error occurred while fetching data.", error);
  //   }
  // };

  const handleProfileData = (selectedFile) => {
    // console.log("dddd");

    const formData = {
      user_id: storedUserPreferences?.id,
      profile: selectedFile,
    };

    axios
      .post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/update/profile/picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message, optionToast);
          setFile("");
          fetchData(userId);
        } else {
          toast.error(res?.data?.message, optionToast);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSaveClick = () => {
    setEditing(false);
    updateProfileDetails(
      1,
      editedData.email,
      editedData.phone,
      editedData.address,
      editedData.addressFirst,
      editedData.addressSecond,
      editedData.city,
      editedData.country,
      editedData.postcode
    );
  };
  // const handleSaveAccountClick = () => {

  //   console.log("Edited Data:", editedData);

  //   setEditingAccount(false);
  //   updateUserBankDetails(editedData.cardNo, editedData.expiry, editedData.cvv);
  // };
  // const handleDeleteClick = () => {
  //   console.log("Edited Data:", editedData);
  // };

  // const formatExpiryDate = (expiry) => {

  //   const digitsOnly = expiry.replace(/\D/g, "");

  //   let formatted = digitsOnly;
  //   if (digitsOnly.length > 2) {
  //     formatted = digitsOnly.slice(0, 2) + "/" + digitsOnly.slice(2);
  //   }

  //   return formatted;
  // };

  // function formatCardNumber(cardNumber) {

  //   const digitsOnly = cardNumber.replace(/\D/g, "");

  //   const formatted = digitsOnly.replace(/(\d{4})/g, "$1 ").trim();

  //   return formatted;
  // }

  useEffect(() => {
    // Extract userId from the query parameter
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");
    // console.log("User ID:", userId);
    setUserId(storedUserPreferences?.id);
    fetchData(userId);
  }, [location.search]);
  // function myfunc() {
  //   const nav = document.getElementById("adv_search");
  //   nav.classList.toggle("d-none");
  // }
  // function myfunction() {
  //   document.getElementById("other-filter").style.display = "block";
  // }
  // function reset() {
  //   document.getElementById("other-filter").style.display = "none";
  // }

  // useEffect(() => {});

  const fetchData = async (userId) => {
    try {
      const response = await axios.post(
        "https://stagingsite.carloca.co.uk/apis/public/api/user/get/details",
        {
          user_id: storedUserPreferences?.id,
        }
      );
      setData(response?.data);
      // console.log(response?.data?.data.bank, "fetch");
      if (response?.data?.status) {
        // setImg(response?.data?.data?.user?.profile)
        setEditedData({
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone || "",
          addressFirst: response?.data?.data?.address_first || "",
          addressSecond: response?.data?.data?.address_second || "",
          city: response?.data?.data?.city || "",
          country: response?.data?.data?.country || "",
          postcode: response?.data?.data?.postcode || "",
          address: response?.data.data?.address || "",
          cardNo: response?.data?.data?.bank?.card_number || "",
          expiry: response?.data?.data?.bank?.expiry || "",
          cvv: response?.data?.data?.bank?.cvv || "", // Set the initial value for address
        });
      }
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="profile_update mt-5 py-5">
        <div className="col-lg-7 col-md-8 col-sm-12 px-3 mx-auto">
          <h2 className="mb-4 fw-bold">Profile Settings</h2>
          <div className="d-flex flex-column settings justify-content-center align-items-center flex-wrap">
            <div className="profileImg">
              <img
                className="update_img"
                alt=""
                src={
                  data?.data?.profile === undefined ||
                  data?.data?.profile === null
                    ? "img/user_default.png"
                    : `${baseUrl}${data?.data?.profile}`
                }
              />
              <label htmlFor="upload-img" className="editbtn">
                <i className="fa-solid fa-pen-to-square"></i>
              </label>
            </div>
            <h4 className="mt-3 fs-5 fw-bold text-primary">
              {data?.data?.first_name} {data?.data?.last_name}
            </h4>
            <div className="d-flex flex-column align-items-center mb-2">
              <input
                id="upload-img"
                onChange={handleProfile}
                type="file"
                style={{ display: "none", visibility: "hidden" }}
              />
            </div>
          </div>
          <div className="card mt-3 border-0">
            <div className="card-body pb-0">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-bold">Your Listing Contact Details</h6>
                <div className="border rounded px-2 py-1">
                  <button
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      fontSize: "12px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={!editing ? handleEditClick : handleSaveClick}
                  >
                    <i className="fa-solid fa-pen-to-square blue me-1"></i>
                    <span className="blue">{!editing ? "Edit" : "Update"}</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Email:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, paddingLeft: "10px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Phone:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.phone}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Address Line 1:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.addressFirst}
                      onChange={(e) =>
                        handleInputChange("addressFirst", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.addressFirst}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Address Line 2:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.addressSecond}
                      onChange={(e) =>
                        handleInputChange("addressSecond", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.addressSecond}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>City:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.city}
                    </span>
                  )}
                </div>
              </div>
              <div className="row ">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Country:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.country}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.country}
                    </span>
                  )}
                </div>
              </div>
              <div className="row pb-3">
                <div className="col mt-2">
                  <span style={{fontSize:"14px",fontWeight:"600"}}>Post Code:</span>
                </div>
                <div className="col mt-2 text-end">
                  {editing ? (
                    <input
                      type="text"
                      value={editedData.postcode}
                      onChange={(e) =>
                        handleInputChange("postcode", e.target.value)
                      }
                      className="form-control"
                      style={{ fontSize: 14, padding: "8px" }}
                    />
                  ) : (
                    <span className="blue text-end" style={{fontSize:"14px"}}>
                      {editedData.postcode}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card mt-3 border-0">
            <div className="card-body pb-0">
              <div className="mb-3 d-flex justify-content-between">
                <div className="w-50">
                  <h6 className="fw-bold">Your Payment Details</h6>
                </div>
                <div className="border rounded px-2 py-1">
                  <button
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      fontSize: "12px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={
                      !editingAccount
                        ? handleEditAcClick
                        : handleSaveAccountClick
                    }
                  >
                    <i className="fa-solid fa-pen-to-square blue me-1"></i>
                    <span className="blue">
                      {!editingAccount ? "Edit" : "Update"}
                    </span>
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-center my-3 mt-5">
                <div className="atm-card p-0">
                  <div className="card-make p-3">
                    <div className="navbar-brand d-flex align-items-center me-4">
                      <img src="img/logo.png" alt="Logo" />
                      
                      <h3 className="head-text">Carloca</h3>
                    </div>
                    <div className="chip"></div>
                    <div className="d-flex justify-content-center mt-2">
                      <input
                        type="text"
                        className="border-0 card-number-1"
                        value={editedData.cardNo}
                        onBlur={(e) =>
                          handleInputChange("cardNo", e.target.value)
                        }
                        placeholder="1234 5678 9012 3456"
                        maxLength="19" 
                        onChange={(e) =>
                          handleInputChange("cardNo", e.target.value)
                        }
                        readOnly={!editingAccount}
                      />
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="ms-1 card-info">
                      <div className="expiry-date">Expiry</div>
                      <div className="cvv mx-4">CVV</div>
                    </div>
                    <div className="card-info">
                      <input
                        type="text"
                        className="border-0 card-number"
                        value={editedData.expiry}
                        onBlur={(e) =>
                          handleInputChange(
                            "expiry",
                            formatExpiryDate(e.target.value)
                          )
                        }
                        placeholder="12/27"
                        maxLength="5"
                        readOnly={!editingAccount}
                        onChange={(e) =>
                          handleInputChange(
                            "expiry",
                            formatExpiryDate(e.target.value)
                          )
                        }
                      />
                      <input
                        type="text"
                        className="border-0 card-number"
                        value={editedData.cvv}
                        onChange={(e) =>
                          handleInputChange("cvv", e.target.value)
                        }
                        placeholder="352"
                        readOnly={!editingAccount}
                        maxLength="3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card mt-3 border-0">
            <div className="card-body pb-0">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-bold ">Account Details</h6>
              </div>
              <ul className="p-0">
                <li className="d-flex justify-content-between mt-2">
                  <span className="">Account Created:</span>
                  <span className=" blue">
                    {moment(data?.data?.created_at).format("YYYY-MM-DD")}
                  </span>
                </li>
                <li className="d-flex justify-content-between mt-2">
                  <span className="">User ID:</span>
                  <span className=" blue">Car-{data?.data?.id}</span>
                </li>
                <li className="d-flex justify-content-between mt-2">
                  <span className="">Status:</span>
                  <span className=" blue">Active</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Link to="/deleteAccount" className="btn btn-sm btn-primary">
              Delete account
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default ProfileSetting;
